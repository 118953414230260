@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Titillium+Web:wght@700&display=swap");

@layer base {
  body {
    font: "montserrat", sans-serif;
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: fit-content;
  height: 220px;
  padding-top: 8px;
  padding-bottom: 10px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(43, 64, 68, 0.8),
    hsla(195, 31%, 42%, 0.8)
  ) !important;
}

.info-div {
  opacity: 0%;
}

.info-div:hover {
  opacity: 100%;
}
